import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { URL_PATH_LOGIN } from '@app/modules/core/routing/constants';
import { DONOR_TOKEN_KEY } from '@app/modules/user-account/constants';
import { MetaService } from '@hae/utils';

const QUERY_DONOR_TOKEN_KEY = 'token';

export const DonorTokenGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const router = inject(Router);
  const metaService = inject(MetaService);

  const donorToken = route.queryParamMap.get(QUERY_DONOR_TOKEN_KEY);

  if (donorToken) {
    metaService.set(DONOR_TOKEN_KEY, donorToken);
    return router.parseUrl(URL_PATH_LOGIN);
  }

  return true;
};
