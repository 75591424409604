import { Routes } from '@angular/router';
import { DonorTokenGuard } from '@app/modules/core/auth/guards/donor-token/donor-token.guard';
import { LanguageGuard } from '@app/modules/core/auth/guards/language/language.guard';
import { LoggedGuard } from '@app/modules/core/auth/guards/logged/logged.guard';
import { LoginGuard } from '@app/modules/core/auth/guards/login/login.guard';
import { TransferGuard } from '@app/modules/core/auth/guards/transfer/transfer.guard';
import { LoggedLayoutComponent } from '@app/modules/core/layout/components/logged-layout/logged-layout.component';
import { NotLoggedLayoutComponent } from '@app/modules/core/layout/components/not-logged-layout/not-logged-layout.component';
import { PageNotFoundComponent } from '@app/modules/page-not-found/page-not-found.component';
import { PreferencesComponent } from '@hae/utils';

import {
  URL_PATH_DONATION,
  URL_PATH_HOME,
  URL_PATH_LOGIN,
  URL_PATH_PAGE_NOT_FOUND,
  URL_PATH_QUESTIONNAIRE,
  URL_PATH_REFERRAL,
  URL_PATH_SETTINGS,
  URL_PATH_USER_ACCOUNT,
  URL_PATH_WELCOME,
} from './constants';
import { DonorLookupResolver } from './resolvers/donor-lookup.resolver';

export const routes: Routes = [
  { path: '', redirectTo: `/${URL_PATH_WELCOME}`, pathMatch: 'full' },
  {
    path: 'privacy-policy',
    loadChildren: () => import('@app/modules/privacy-policy/privacy-policy.routes').then(
      (m) => m.routes,
    ),
  },
  {
    path: '',
    canActivate: [TransferGuard, LanguageGuard, DonorTokenGuard],
    children: [
      // Logged routes goes here
      {
        path: '',
        component: LoggedLayoutComponent,
        canActivate: [LoginGuard],
        children: [
          {
            path: URL_PATH_HOME,
            loadChildren: () => import('@app/modules/home/home.routes').then((m) => m.routes),
            resolve: { donor: DonorLookupResolver },
          },
          {
            path: URL_PATH_SETTINGS,
            component: PreferencesComponent,
          },
          {
            path: URL_PATH_USER_ACCOUNT,
            loadChildren: () => import('@app/modules/user-account/user-account.routes').then(
              (m) => m.routes,
            ),
          },
          {
            path: URL_PATH_QUESTIONNAIRE,
            loadChildren: () => import('@app/modules/questionnaire/questionnaire.routes').then(
              (m) => m.routes,
            ),
          },
          {
            path: URL_PATH_DONATION,
            loadChildren: () => import('@app/modules/donation/donation.routes').then(
              (m) => m.routes,
            ),
          },
        ],
      },

      // Not logged routes goes here
      {
        path: '',
        component: NotLoggedLayoutComponent,
        canActivate: [LoggedGuard],
        children: [
          {
            path: URL_PATH_LOGIN,
            loadChildren: () => import('@app/modules/login/login.routes').then((m) => m.routes),
          },
          {
            path: URL_PATH_WELCOME,
            loadChildren: () => import('@app/modules/welcome/welcome.routes').then(
              (m) => m.routes,
            ),
          },
        ],
      },
      {
        path: '',
        canActivate: [LoggedGuard],
        children: [
          {
            path: URL_PATH_REFERRAL,
            loadChildren: () => import('@app/modules/referral/referral.routes').then(
              (m) => m.routes,
            ),
          },
        ],
      },
    ],
  },
  { path: URL_PATH_PAGE_NOT_FOUND, component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];
