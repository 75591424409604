import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { I18nStateSelectors, TaskLoadedSuccessfully } from '@app/store';
import { DialogComponent } from '@hae/utils';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import {
  ERROR_MESSAGE_DEFAULT_ENG,
  ERROR_MESSAGE_DEFAULT_ESP,
  ERROR_TITLE_DEFAULT_ENG,
  ERROR_TITLE_DEFAULT_ESP,
  ERRORS_MODAL_MESSAGE_TRANSLATION_KEY,
  ERRORS_MODAL_TITLE_TRANSLATION_KEY,
} from '../constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  public currentLanguage$ = this.store.select(
    I18nStateSelectors.currentLanguage,
  );

  private errorTitle = ERROR_TITLE_DEFAULT_ENG;

  private errorMessage = ERROR_MESSAGE_DEFAULT_ENG;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private store: Store,
  ) {
    this.currentLanguage$.subscribe((lang) => {
      this.translate
        .get(ERRORS_MODAL_TITLE_TRANSLATION_KEY)
        .subscribe((res) => {
          if (res === ERRORS_MODAL_TITLE_TRANSLATION_KEY) {
            this.errorTitle = lang === 'en-US'
              ? ERROR_TITLE_DEFAULT_ENG
              : ERROR_TITLE_DEFAULT_ESP;
          } else {
            this.errorTitle = res;
          }
        });
      this.translate
        .get(ERRORS_MODAL_MESSAGE_TRANSLATION_KEY)
        .subscribe((res) => {
          if (res === ERRORS_MODAL_MESSAGE_TRANSLATION_KEY) {
            this.errorMessage = lang === 'en-US'
              ? ERROR_MESSAGE_DEFAULT_ENG
              : ERROR_MESSAGE_DEFAULT_ESP;
          } else {
            this.errorMessage = res;
          }
        });
    });
  }

  intercept(
    request: HttpRequest<never>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const requestUrl = error?.url || '';
        if (
          error.status === 503
          || ((error.status >= 500
            || error.status === 400
            || error.status === 0)
            && ![
              'interviews',
              'lookup',
              'user-agreements',
              'preferences',
              'interview-submission-id',
            ].some((url) => requestUrl.includes(url)))
        ) {
          const code = error.error?.error?.code;
          this.dialog.open(DialogComponent, {
            data: {
              title: this.errorTitle,
              text: code ? this.translate.instant(code) : this.errorMessage,
              errorCode: '000',
            },
          });
        }
        return throwError(() => error);
      }),
      finalize(() => new TaskLoadedSuccessfully()),
    );
  }
}
