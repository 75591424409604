import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CognitoService } from '@hae/auth';
import { map } from 'rxjs/operators';

export const LoginGuard: CanActivateFn = () => {
  const router = inject(Router);
  const cognitoService = inject(CognitoService);

  return cognitoService.isLoggedIn().pipe(
    map((isUserLogged) => {
      if (isUserLogged) {
        return true;
      }
      // Navigate to the login page
      const loginUrl = '/login';
      return router.parseUrl(loginUrl);
    }),
  );
};
